<template>
  <div class="formView bg-main url-form-view">
    <!-- TOOLBAR -->
    <v-row class="style-toolbar-life px-4">
      <v-col cols="2" sm="2" md="2" lg="2" xl="1">
        <div class="d-flex align-center pa-4"><v-img src="../assets/logos/logo_via_plata_white.svg" width="54%"></v-img></div>
      </v-col>
      <v-col cols="10" sm="10" md="10" lg="10" xl="4">
        <v-row>
          <v-col cols="2" sm="2" md="2" lg="2" xl="2">
            <div class="d-flex align-center pa-2 mt-2"><v-img src="../assets/logos/logo_GRID_white.svg" width="100%"></v-img></div>
          </v-col>
          <v-col class="d-flex align-center">
            <div class="text-toolbar mt-2">Gestor de Recursos e Indicadores Digitales</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex align-center" cols="12" sm="6" md="3" lg="4" xl="2">
        <v-btn class="btn-normal" @click="allExpand">EXPANDIR TODO</v-btn>
      </v-col>
      <v-col class="d-flex align-center" cols="12" sm="6" md="3" lg="4" xl="2">
        <v-btn class="btn-normal" @click="noneExpand">CONTRAER TODO</v-btn>
      </v-col>
      <v-col class="d-flex align-center justify-content-end" cols="12" sm="6" md="3" lg="2" xl="2">
        <div class="text-toolbar-user ml-6">
          <span class="mdi mdi-account mr-4"></span>{{ this.currentNameUser }}
        </div>
      </v-col>
      <v-col class="d-flex align-center" cols="12" sm="6" md="3" lg="2" xl="1">
        <v-btn class="btn-red my-2" @click="showDialogLogout"><span class="mdi mdi-logout"></span></v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0 display-all mt-4">
      <v-col cols="12" sm="12" md="12" lg="12" xl="11" class="bg-white-center mx-auto">
        <div>
          <v-expansion-panels v-model="activePanel" multiple @change="controlExpandPanels">
            <v-expansion-panel v-for="itemList in dataALL" :key="itemList.id" class="elevation-0 my-2" :style="itemList.style">
              <v-expansion-panel-header>
                <div>
                  <div class="d-inline mr-4">
                    <img v-bind:src="require('../assets/icons/' + itemList.icon)" width="25" height="25" />
                  </div>
                  <div class="d-inline">{{ itemList.name }}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card color="white lighten-1" class="mb-12">
                  <v-container fluid>
                    <!-- Content table -->
                    <template>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="itemList.searchTable"
                            prepend-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-autocomplete
                            v-model="itemList.currentTablesSelected"
                            :items="itemList.variableList"
                            label="Filtrar tablas"
                            prepend-icon="mdi-filter-variant"
                            @change="filterTable(itemList)"
                            item-text="name"
                            return-object
                            multiple
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <div v-for="itemTable in itemList.tables" :key="itemTable.id">
                        <v-row v-if="itemTable.visible == true" class="mt-6">
                          <v-col v-if="itemTable.name != ''">
                            <!-- FORM -->
                            <v-form class="formLogin" :ref="itemTable.refFormMod" v-model="itemTable.validFormMod" lazy-validation>
                              <div class="bg-title-table-header">({{itemTable.name}}) - {{ itemTable.title }}</div>
                              <v-data-table
                                :headers="itemTable.headers"
                                :items="itemTable.data"
                                :search="itemList.searchTable"
                                :sort-by="[]"
                                :sort-desc="[]"
                                multi-sort
                                dense
                                :footer-props="{
                                  showFirstLastPage: true,
                                  firstIcon: 'mdi-arrow-collapse-left',
                                  lastIcon: 'mdi-arrow-collapse-right',
                                  prevIcon: 'mdi-minus',
                                  nextIcon: 'mdi-plus',
                                  'items-per-page-text':'Datos por página'
                                }"
                              >
                                <template v-slot:[`item.date`]="{ item }">
                                  <span>{{ formatDateTable(item.date) }}</span>
                                </template>
                                <!-- EDIT TABLE -->
                                <template v-slot:[`item.actions`]="{ item }">
                                  <v-menu v-model="item.menuEdit" left offset-x offset-y :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="loadInfoMod(item, itemTable)">mdi-pencil</v-icon>
                                    </template>
                                    <v-list>
                                      <v-list-item>
                                        <v-row>
                                          <v-col class="mt-3">
                                            <div class="d-flex align-items-center">
                                              <div class="inline">
                                                <v-icon>mdi-calendar-range</v-icon>
                                              </div>
                                              <div class="inline ml-2">
                                                <v-text-field
                                                  class="inputNumberDate"
                                                  v-model="itemTable.editValueDay"
                                                  label="día"
                                                  :minlength="minDigit"
                                                  :maxlength="maxDigit"
                                                  :rules="[(v) => !!v || 'Campo obligatorio' ]"
                                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                                  required
                                                ></v-text-field>
                                              </div>
                                              <div class="inline mx-2"><span> / </span></div>
                                              <div class="inline ml-2">
                                                <v-text-field class="inputNumberDate"
                                                  v-model="itemTable.editValueMonth"
                                                  label="mes"
                                                  :minlength="minDigit"
                                                  :maxlength="maxDigit"
                                                  :rules="[(v) => !!v || 'Campo obligatorio' ]"
                                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                                  required
                                                ></v-text-field>
                                              </div>
                                              <div class="inline mx-2"><span> / </span></div>
                                              <div class="inline ml-2">
                                                <v-text-field
                                                  class="inputNumberDate"
                                                  v-model="itemTable.editValueYear"
                                                  label="año"
                                                  :minlength="minDigit"
                                                  :maxlength="maxDigitYear"
                                                  :rules="[(v) => !!v || 'Campo obligatorio' ]"
                                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                                  required
                                                ></v-text-field>
                                              </div>
                                            </div>
                                          </v-col>
                                          <v-col class="mt-3" v-if="itemTable.editValues.length != 0 && itemTable.editValues[0] != undefined && itemTable.editValues[0].value != undefined && itemTable.editValues[0].value.x != undefined">
                                            <v-row>
                                              <v-col>
                                                <v-text-field
                                                  v-model="itemTable.editValues[0].value.x"
                                                  label="Valor x"
                                                  placeholder="Sólo números"
                                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                                ></v-text-field>                                        
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                          <v-col class="mt-3" v-if="itemTable.editValues.length != 0 && itemTable.editValues[0] != undefined &&  itemTable.editValues[0].value != undefined && itemTable.editValues[0].value.y != undefined">
                                            <v-row>
                                              <v-col>
                                                <v-text-field
                                                  v-model="itemTable.editValues[0].value.y"
                                                  label="Valor y"
                                                  placeholder="Sólo números"
                                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                                ></v-text-field>                                        
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                          <v-col class="mt-3" v-if="itemTable.editValues.length != 0 && itemTable.editValues[0] != undefined &&  itemTable.editValues[0].value != undefined && itemTable.editValues[0].value.z != undefined">
                                            <v-row>
                                              <v-col>
                                                <v-text-field
                                                  v-model="itemTable.editValues[0].value.z"
                                                  label="Valor z"
                                                  placeholder="Sólo números"
                                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                                ></v-text-field>                                        
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                          <v-col class="mt-3" v-if="itemTable.editValues[0] && itemTable.editValues[0].name == 'value'">
                                            <v-row>
                                              <v-col>
                                                <v-text-field
                                                  v-model="itemTable.editValues[0].value"
                                                  label="Valor"
                                                  placeholder="Sólo números"
                                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                                ></v-text-field>                                        
                                              </v-col>
                                            </v-row>
                                          </v-col>

                                          <v-col class="mt-3" v-for="itemEdit in arrayNewData(itemTable.editValues)" :key="itemEdit.name">
                                            <v-text-field v-if="itemEdit.type == 'text'"
                                              v-model="itemEdit.value"
                                              :label="itemEdit.name"
                                            ></v-text-field>
                                            <v-text-field v-if="itemEdit.type == 'number'"
                                              v-model="itemEdit.value"
                                              :label="itemEdit.name"
                                              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                              placeholder="Sólo números"
                                            ></v-text-field>
                                            <v-autocomplete  v-if="itemEdit.type == 'combo'"
                                              v-model="itemEdit.value"
                                              :items="itemList.list"
                                              :label="itemEdit.name"
                                              item-text="name"
                                              return-object
                                            ></v-autocomplete>
                                            <v-menu v-if="itemEdit.type == 'date'"
                                              v-model="itemEdit.menu"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                  v-model="itemEdit.value"
                                                  label="Picker without buttons"
                                                  prepend-icon="mdi-calendar"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker 
                                                v-model="itemEdit.value" 
                                                @input="itemEdit.menu = false">
                                              </v-date-picker>
                                            </v-menu>
                                            <v-row class="ma-0 pa-0" v-if="itemEdit.type == 'obj'">
                                              <v-col class="ma-0 pa-0">
                                                <v-text-field
                                                  v-model="itemEdit.value"
                                                  :label="itemEdit.name"
                                                ></v-text-field>
                                              </v-col>
                                            </v-row>
                                            <div class="text-data-table" v-if="itemEdit.name == 'Unidad'"> 
                                              <div class="text-center">Unidad: </div>  
                                              <div v-if="itemTable.data[0] != undefined" class="text-data-table-info text-center">{{ itemTable.data[0].unit }}</div>
                                            </div>
                                            <div class="text-data-table" v-if="itemEdit.name == 'Usuario'"> 
                                              <div class="text-center">Usuario: </div>  
                                              <div class="text-data-table-info text-center">{{ currentNameUser }}</div>
                                            </div>
                                          </v-col>
                                        </v-row>
                                        <v-row class="ma-0 pa-0">
                                          <v-col class="ma-0 pa-0 pa-2">
                                            <v-btn class="btn-normal" @click="editChangesTable(item, itemTable), item.menuEdit = false">CONFIRMAR</v-btn>
                                          </v-col>
                                          <v-col class="ma-0 pa-0 pa-2">
                                            <v-btn class="btn-red" @click="item.menuEdit = false">CANCELAR</v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>

                                  <v-icon class="mr-2" small @click="deleteItem(item, itemTable, itemList.variableList)">mdi-delete</v-icon>
                                  <!-- <v-icon v-if="showIconMap(item) == true" small @click="goToGisMap(item, itemTable, itemList.variableList)">mdi-map</v-icon>-->
                                  
                                </template>
                              </v-data-table>

                              <v-row v-if="showIconMap(itemTable.data[0]) == true" class="d-flex justify-center align-items-center mb-4 mx-1">
                                <v-col>
                                  <v-btn class="btn-normal" @click="goToGisMap(itemTable.data[0], itemTable, itemList.variableList)">VER MAPA</v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-col>
                          <v-col v-else>
                            <div class="text-warm-table">No hay información relacionada con este campo. Por favor, descargue la plantilla de excel y rellene la información necesaria para empezar.</div>
                          </v-col>

                          <!-- ADD NEW ITEM -->
                          <v-form v-if="itemTable.name != ''" class="formAdd w-100" :ref="itemTable.refFormAdd" v-model="itemTable.validFormAdd" lazy-validation>
                            <v-row class="d-flex justify-center align-items-center ma-0 pa-0 mt-4">

                              <v-col class="mt-3">
                                <div class="d-flex align-items-center">
                                  <div class="inline">
                                    <v-icon>mdi-calendar-range</v-icon>
                                  </div>
                                  <div class="inline ml-2">
                                    <v-text-field
                                      class="inputNumberDate"
                                      v-model="itemTable.newValueDay"
                                      label="día"
                                      :minlength="minDigit"
                                      :maxlength="maxDigit"
                                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                    ></v-text-field>
                                  </div>
                                  <div class="inline mx-2"><span> / </span></div>
                                  <div class="inline ml-2">
                                    <v-text-field
                                      class="inputNumberDate"
                                      v-model="itemTable.newValueMonth"
                                      label="mes"
                                      :minlength="minDigit"
                                      :maxlength="maxDigit"
                                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                    ></v-text-field>
                                  </div>
                                  <div class="inline mx-2"><span> / </span></div>
                                  <div class="inline ml-2">
                                    <v-text-field
                                      class="inputNumberYear"
                                      v-model="itemTable.newValueYear"
                                      label="año"
                                      :minlength="minDigit"
                                      :maxlength="maxDigitYear"
                                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                    ></v-text-field>
                                  </div>
                                </div>
                              </v-col>
                              <v-col v-if="itemTable.newValues.length != 0 && itemTable.newValues[0] != undefined && itemTable.newValues[0].value != undefined && itemTable.newValues[0].value.x != undefined">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model="itemTable.newValues[0].value.x"
                                      label="Valor x"
                                      placeholder="Sólo números"
                                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                    ></v-text-field>                                        
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col v-if="itemTable.newValues.length != 0 && itemTable.newValues[0] != undefined &&  itemTable.newValues[0].value != undefined && itemTable.newValues[0].value.y != undefined">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model="itemTable.newValues[0].value.y"
                                      label="Valor y"
                                      placeholder="Sólo números"
                                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                    ></v-text-field>                                        
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col v-if="itemTable.newValues.length != 0 && itemTable.newValues[0] != undefined &&  itemTable.newValues[0].value != undefined && itemTable.newValues[0].value.z != undefined">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model="itemTable.newValues[0].value.z"
                                      label="Valor z"
                                      placeholder="Sólo números"
                                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                    ></v-text-field>                                        
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col v-if="itemTable.newValues[0] && itemTable.newValues[0].name == 'value'">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model="itemTable.newValues[0].value"
                                      label="Valor"
                                      placeholder="Sólo números"
                                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                    ></v-text-field>                                        
                                  </v-col>
                                </v-row>
                              </v-col>

                              <v-col class="d-flex align-center justify-center mt-3">
                                <div class="text-data-table"> 
                                  <span>Unidad: </span>  
                                  <span v-if="itemTable.data[0] != undefined" class="text-data-table-info">{{ itemTable.data[0].unit }}</span>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="d-flex justify-center align-items-center px-4">
                              <v-col v-for="itemNew in arrayNewData(itemTable.newValues)" :key="itemNew.name">
                                <v-text-field v-if="itemNew.type == 'text'"
                                  v-model="itemNew.value"
                                  :label="itemNew.name"
                                ></v-text-field>
                                <v-text-field v-if="itemNew.type == 'number'"
                                  v-model="itemNew.value"
                                  :label="itemNew.name"
                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                  placeholder="Sólo números"
                                ></v-text-field>
                                <v-autocomplete  v-if="itemNew.type == 'combo'"
                                  v-model="itemNew.value"
                                  :items="itemList.list"
                                  :label="itemNew.name"
                                  item-text="name"
                                  return-object
                                ></v-autocomplete>
                                <v-menu v-if="itemNew.type == 'date'"
                                  v-model="itemNew.menu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="itemNew.value"
                                      label="Picker without buttons"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker 
                                    v-model="itemNew.value" 
                                    @input="itemNew.menu = false">
                                  </v-date-picker>
                                </v-menu>
                                <v-row class="ma-0 pa-0" v-if="itemNew.type == 'obj'">
                                  <v-col>
                                    <v-text-field
                                      v-model="itemNew.value"
                                      :label="itemNew.name"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>     
                            </v-row>

                            <v-row class="d-flex justify-center align-items-center mb-4 mx-1">
                              <v-col>
                                <v-btn class="btn-normal" @click="addNewRow(itemTable, itemList)" :disabled="itemTable.disableAdd">AÑADIR</v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                          
                          <v-row class="mx-2" style="width: 100% !important">
                            <v-col>
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                          
                          <!-- LOAD DATA EXCEL -->
                          <v-form class="formExcel" ref="formNewAnimalExcel" style="width: 100% !important" v-model="itemTable.validFormExcel" lazy-validation>
                            <v-row class="d-flex justify-center align-items-center ma-0 pa-0">
                              <v-col cols="8">
                                <div class="text-body-style-white pl-2">Utilizar un fichero excel para cargar varios datos en esta tabla:</div>
                                <div class="pt-4">
                                  <!-- Styled -->
                                  <b-form-file
                                    size="lg"
                                    v-model="itemTable.fileExcel"
                                    :state="Boolean(itemTable.fileExcel)"
                                    placeholder="Seleccionar un archivo o arrastrar aqui"
                                    drop-placeholder="Arrastrando aqui..."
                                    color="#446d08"
                                    accept=".xlsx, .xlsb"
                                    required 
                                    @change="activateBtnExcel(itemTable)"
                                  ></b-form-file>
                                </div>
                                <!-- 
                                <v-file-input id="idInputFile" class="textFieldStyleInput" label="SELECCIONAR ARCHIVO EXCEL"
                                  name="file"
                                  v-model="itemTable.fileExcel"
                                  accept=".xlsx, .xlsb"
                                  color="#446d08"
                                  required   
                                  @change="activateBtnExcel(itemTable)"                       
                                >
                                </v-file-input>
                                -->
                              </v-col>
                              <v-col cols="4">
                                <v-btn class="btn-neutral mb-1" @click="downloadTemplateExcel(itemTable)" :disabled="itemTable.disableExcel">DESCARGAR PLANTILLA EXCEL</v-btn>
                                <v-btn class="btn-neutral my-1" @click="downloadDataExcel(itemTable)" :disabled="itemTable.disableExcel">DESCARGAR EXCEL DE DATOS</v-btn>
                                <v-btn class="btn-normal mt-1" @click="loadDataExcel(itemTable)" :disabled="itemTable.disableExcel">CARGAR EXCEL</v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-row>
                      </div>
                    </template>
                  </v-container>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
    <!-- DIALOG -->
    <DialogRemove ref="dialogRemove" @resRemove="resRemove" />
    <DialogError ref="dialogError" />
    <DialogSuccess ref="dialogSuccess" />
    <DialogLogout ref="dialogLogout" />
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#446d08">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#d7f7a7" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import config from '../config.json';
import allVariables from '../allVariables.json';
import allsectionVariables from '../allsectionVariables.json';
import {json2excel} from 'js2excel'; // https://github.com/dwqs/js2excel
export default {
  data() {
    return {
      filterField: config.host + config.apiUrl + config.filterDataRecords,
      urlDataRecords: config.host + config.apiUrl + config.genericDataRecords,
      userLocal: undefined,
      currentNameUser: 'NombreUsuario',
      waitLoadScreen: false,
      textLoader: '',
      modelSteps: 1,
      minDigit: 1,
      maxDigit: 2,
      maxDigitYear: 4,
      listAUX: [],
      idSectionAUx: -1,
      valid: true,
      selectItemList: undefined,
      dateValueOptions: '',
      variableValueOptions: '',
      valueValueOptions: undefined,
      unitValueOptions: '',
      userValueOptions: '',
      currentGroupAux: '',
      arrayManager: [],
      arrayDiffusion: [],
      dataExcelTemplate: {},
      activePanel: [0],
      dataALL: allsectionVariables,      
    };
  },
  components: {
    DialogRemove: () => import("../components/dialogRemove"),
    DialogError: () => import("../components/dialogError"),
    DialogSuccess: () => import("../components/DialogSuccess"),
    DialogLogout: () => import("../components/DialogLogout")
  },
  async mounted(){
    this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
    this.currentNameUser = this.userLocal.name;
    this.dateToday();
    await this.getDataGeneric();
    await this.selectFirstTable();
  },
  methods: {
    controlExpandPanels(){
      for (const item of this.dataALL) {
        let flag = false;
        for (const num of this.activePanel) {
          if(num == item.id){
            flag = true;
          }
        }
        if(flag){
          item.icon = this.calculateSrcIcon(item.id, true);
          item.style = "background-color: transparent; color: #91b83b"
        }
        else{
          item.icon = this.calculateSrcIcon(item.id, false);
          item.style = "background-color: transparent; color: #446d08"
        }
      }
    },
    calculateSrcIcon(num, active){
      if(num == 0){
        if(active){
          return "ic_air_on.svg"
        }
        else{
          return "ic_air_off.svg"
        }
      }
      else if(num == 1){
        if(active){
          return "ic_culture_on.svg"
        }
        else{
          return "ic_culture_off.svg"
        }
      }
      else if(num == 2){
        if(active){
          return "ic_diffusion_on.svg"
        }
        else{
          return "ic_diffusion_off.svg"
        }
      }
      else if(num == 3){
        if(active){
          return "ic_wildlife_on.svg"
        }
        else{
          return "ic_wildlife_off.svg"
        }
      }
      else if(num == 4){
        if(active){
          return "ic_meteo_on.svg"
        }
        else{
          return "ic_meteo_off.svg"
        }
      }
      else if(num == 5){
        if(active){
          return "ic_health_on.svg"
        }
        else{
          return "ic_health_off.svg"
        }
      }
      else if(num == 6){
        if(active){
          return "ic_awareness_on.svg"
        }
        else{
          return "ic_awareness_off.svg"
        }
      }
      else if(num == 7){
        if(active){
          return "ic_eco_social_on.svg"
        }
        else{
          return "ic_eco_social_off.svg"
        }
      }
      else if(num == 8){
        if(active){
          return "ic_territory_on.svg"
        }
        else{
          return "ic_territory_off.svg"
        }
      }
      else if(num == 9){
        if(active){
          return "ic_vegetation_on.svg"
        }
        else{
          return "ic_vegetation_off.svg"
        }
      }
      else if(num == 10){
        if(active){
          return "ic_manager_indicators_on.svg"
        }
        else{
          return "ic_manager_indicators_off.svg"
        }
      }
      else if(num == 11){
        if(active){
          return "ic_diffusion_indicators_on.svg"
        }
        else{
          return "ic_diffusion_indicators_off.svg"
        }
      }
      else{
        return undefined;
      }
    },
    allExpand(){
      this.activePanel = [0,1,2,3,4,5,6,7,8,9,10,11];
      for (const item of this.dataALL) {
        item.icon = this.calculateSrcIcon(item.id, true);
        item.style = "background-color: transparent; color: #91b83b"
      }
    },
    noneExpand(){
      this.activePanel = [];
      for (const item of this.dataALL) {
        item.icon = this.calculateSrcIcon(item.id, false);
        item.style = "background-color: transparent; color: #446d08"
      }
    },
    resField(item){
      if(item.name != "value" && item.name != "Fecha" && item.name != "Unidad" && item.name != "Usuario" && item.name != "Variable"){
        return true;
      }
      return false;
    },
    dialogEdit(){
      this.edit = true;
    },
    formatDateTable(date){
      let part = date.split("T")[0].split("-");
      let finalDate = part[2] + "/" + part[1] + "/" + part[0];
      return finalDate;
    },
    filterTable(item){
      for (const i in item.tables) {
        let contentName = item.currentTablesSelected.find(x => x.name === item.tables[i].title);
        if(contentName != undefined){
          item.tables[i].visible = true;
        }
        else{
          item.tables[i].visible = false;
        }
      }
    },
    async getDataGeneric(){
      this.waitLoadScreen = true;
      this.textLoader = 'Cargando datos...';
      let currentGroup = '';
      
      for await(const elem of Object.keys(allVariables)) {
        currentGroup = elem;
        for await(const data of allVariables[elem]) {
          if(data.field != ""){
            await this.getDataField(currentGroup, data);
          }
          else{
            await this.assignGroup([], currentGroup, data);
          }
        }
      }
      this.waitLoadScreen = false; 
    },
    async getDataField(group, data){
      let resultCollection = undefined;
      await axios.get(this.filterField + data.field + "&limit=100000",
        {
          headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userLocal.token}`
          }
        }
      )
      .then(async authoritation => {
        resultCollection = authoritation.data;
        await this.assignGroup(resultCollection, group, data);
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        return undefined;
      });
      if(!resultCollection){
        this.waitLoadScreen = false;
        /*
        this.titleDialog = "ERROR DE DATOS";
        this.textDialog = "Ha surgido un error inesperado en la colección: "+data.name+" // Nº: " + data.field + " // GRUPO: " + group;
        this.showDialogError();
        */
      }
    },
    async assignGroup(collection, nameGroup, dataGroup){
      if(nameGroup == "airQuality"){
        this.createNewTable(0, collection, dataGroup);
      }
      else if(nameGroup == "culture"){
        this.createNewTable(1, collection, dataGroup);
      }
      else if(nameGroup == "diffusion"){
        this.createNewTable(2, collection, dataGroup);
      }
      else if(nameGroup == "wildlife"){
        this.createNewTable(3, collection, dataGroup);
      }
      else if(nameGroup == "meteo"){
        this.createNewTable(4, collection, dataGroup);
      }
      else if(nameGroup == "health"){
        this.createNewTable(5, collection, dataGroup);
      }
      else if(nameGroup == "awareness"){
        this.createNewTable(6, collection, dataGroup);
      }
      else if(nameGroup == "ecoSocial"){
        this.createNewTable(7, collection, dataGroup);
      }
      else if(nameGroup == "territory"){
        this.createNewTable(8, collection, dataGroup);
      }
      else if(nameGroup == "vegetation"){
        this.createNewTable(9, collection, dataGroup);
      }
      else if(nameGroup == "managerIndicators"){
        this.arrayManager = [];
        this.arrayDiffusion = [];
        if(dataGroup.field != ""){
          for (const elem of collection) {
            let part = Number(elem.field.split("I-GES-")[1]);
            if(isNaN(part)){
              if(elem.field.split("I-GES-")[1] == "42a" || elem.field.split("I-GES-")[1] == "42b"){
                this.arrayDiffusion.push(elem);
              }
            }
            else{
              if(part >= 34 && part <= 46){
                this.arrayDiffusion.push(elem);
              }
              else{
                this.arrayManager.push(elem);
              }
            }
          }
        }
        this.createNewTable(10, this.arrayManager, dataGroup);
      }
      else if(nameGroup == "diffusionIndicators"){
        this.createNewTable(11, this.arrayDiffusion, dataGroup);
      }
      else {
        this.titleDialog = "ERROR DE DATOS";
        this.textDialog = "El grupo " + nameGroup + " no coincide con ninguno existente.";
        this.showDialogError();
      }
    },
    createNewTable(pos, dataCollection, dataGroup){
      let countHeader = 0;
      let dataHeaders = []; // Generate Header
      let newEdit = []; // Generate edit
      let newRow = []; // Generate new row table

      dataHeaders.push({ text: 'Fecha', value: 'date', align: 'start', });

      if(dataGroup.field != ""){
        if(typeof dataCollection[0].value === 'string'){
          dataHeaders.push({ text: 'Valor', value: 'value' });
          newEdit.push({ id: countHeader, name: 'value', value: undefined, type:"text" });
          newRow.push({ id: countHeader, name: 'value', value: undefined, type:"text" });
          countHeader++;
        }
        else if(typeof dataCollection[0].value === 'number'){
          dataHeaders.push({ text: 'Valor', value: 'value' });
          newEdit.push({ id: countHeader, name: 'value', value: undefined, type:"number" });
          newRow.push({ id: countHeader, name: 'value', value: undefined, type:"number" });
          countHeader++;
        }
        else if(typeof dataCollection[0].value === 'object'){
          let num = Object.keys(dataCollection[0].value).length;
          for (let i = 0; i < num; i++) {
            dataHeaders.push({ text: 'Valor ' + Object.keys(dataCollection[0].value)[i], value: 'value.' + Object.keys(dataCollection[0].value)[i] });
          }
          newEdit.push({ id: countHeader, name: "valueObj", value: dataCollection[0].value, type:"obj" });
          let resultNew = this.emptyDataNew(dataCollection[0].value);
          newRow.push({ id: countHeader, name: "valueObj", value: resultNew, type:"obj" });
          countHeader++;
        }
        else{
          console.error("ERROR: No se ha identificado el value de " + dataGroup.field + " en el header");
        }

        dataCollection[0].tags.forEach(async tag => {
        
          let nameValue = tag.name.replace(' ', ''); // remove spaces

          if(typeof tag.value === 'string'){
            dataHeaders.push({ text: tag.name, value: nameValue });
            newEdit.push({ id: countHeader, name: tag.name, value: undefined, type:"text" });
            newRow.push({ id: countHeader, name: tag.name, value: undefined, type:"text" });
            countHeader++;
          }
          else if(typeof tag.value === 'number'){
            dataHeaders.push({ text: tag.name, value: nameValue });
            newEdit.push({ id: countHeader, name: tag.name, value: undefined, type:"number" });
            newRow.push({ id: countHeader, name: tag.name, value: undefined, type:"number" });
            countHeader++;
          }
          else if(typeof tag.value === 'object'){
            if(Array.isArray(tag.value)){
              for(let [key, value] of tag.value.entries()){
                dataHeaders.push({ text: tag.name + "." + key, value: nameValue + "." + key });
                newEdit.push({ id: countHeader, name: tag.name + "." + key, value: value, type:"obj" });
                newRow.push({ id: countHeader, name: tag.name + "." + key, value: undefined, type:"obj" });
                countHeader++;
              }
            }
            else {
              for(let key of Object.keys(tag.value)){
                dataHeaders.push({ text: tag.name + "." + key, value: nameValue + "." + key});
                newEdit.push({ id: countHeader, name: tag.name + "." + key, value: tag.value[key], type:"obj" });
                newRow.push({ id: countHeader, name: tag.name + "." + key, value: undefined, type:"obj" });
                countHeader++;
              }
            }
          }
          else {
            dataHeaders.push({ text: tag.name, value: nameValue });
            newEdit.push({ id: countHeader, name: tag.name, value: tag.value, type:"?" });
            newRow.push({ id: countHeader, name: tag.name, value: undefined, type:"?" });
            countHeader++;
          }
        });
      }

      if(dataGroup.field != 'I-GES'){
        dataHeaders.push({ text: 'Unidad', value: 'unit' });
      }
      dataHeaders.push({ text: 'Usuario', value: 'user' });
      dataHeaders.push({ text: 'Acciones', value: 'actions', sortable: false });

      if(dataGroup.field != 'I-GES'){
        newEdit.push({ id: countHeader, name: 'Unidad', value: dataGroup.unit, type:"read" });
        newRow.push({ id: countHeader, name: 'unit', value: dataGroup.unit, type:"text" });
        countHeader++;

        newEdit.push({ id: countHeader, name: 'Usuario', value: this.currentNameUser, type:"read" });
        countHeader++;
      }
      
      // Generate data table
      let newData = [];
      dataCollection.forEach(async elem => {
        let obj = { posSection: pos, nameTable: dataGroup.field, id: elem.id, menuEdit: false  };
        dataHeaders.forEach(async header => {
          if(header.value === 'date'){
            obj[header.value] = elem[header.value];
          }
          else if(header.value.startsWith("value")){
            let parts = header.value.split(".");
            if(parts.length > 1){
              obj[header.value] = elem[parts[0]][parts[1]]
            }
            else {
              obj[header.value] = elem[header.value]
            }
          }
          else if(header.value === 'id'){
            obj[header.value] = dataGroup.id
          }
          else if(header.value === 'unit'){
            obj[header.value] = dataGroup.unit
          }
          else if(header.value === 'user'){
            obj[header.value] = elem.user
          }
          else {
            let parts = header.text.split(".");
            if(parts.length > 1){
              let tag = elem.tags.find(x => x.name === parts[0]);
              if(tag){
                if(Array.isArray(tag.value)){
                  obj[header.value] = tag.value[Number(parts[1])]
                }
                else {
                  obj[header.value] = tag.value[parts[1]]
                }
              }
            }
            else {
              let tag = elem.tags.find(x => x.name === header.text);
              if(tag){
                obj[header.value] = tag.value
              }
            }
          }          
        });
        newData.push(obj);
      });

      let date = new Date();
      let todayDay = String(date.getDate());
      let todayMonth = String(date.getMonth() + 1);
      let todayYear = String(date.getFullYear());

      // SET new table
      this.dataALL[pos].tables.push(
        {
          title: dataGroup.name,
          name: dataGroup.field,
          visible: false,
          headers: dataHeaders,
          data: newData,  
          editValueDay: undefined,
          editValueMonth: undefined,
          editValueYear: undefined,
          editValues: newEdit,
          newValueDay: todayDay,
          newValueMonth: todayMonth,
          newValueYear: todayYear,
          newValues: newRow,
          refFormEdit: dataGroup.field + "FormMod",
          validFormEdit: true,
          refFormAdd: dataGroup.field + "FormAdd",
          validFormAdd: true,   
          refFormExcel: dataGroup.field + "FormExcel",
          validFormExcel: true,   
          fileExcel: null,
        }
      );

      this.dataALL[pos].variableList.push(dataGroup);
    },
    async selectFirstTable(){
      for (const i in this.dataALL) {
        let tableFirt = undefined;
        for (const y in this.dataALL[i].tables) {
          if(this.dataALL[i].tables[y].data.length != 0){
            this.dataALL[i].tables[y].visible = true;
            tableFirt = await this.searchDataVariable(this.dataALL[i].tables[y].title);
            this.dataALL[i].currentTablesSelected.push(tableFirt);
            break;
          }
        }
        if(tableFirt == undefined){
          for (const x in this.dataALL[i].tables) {
            if(x == 0){
              this.dataALL[i].tables[x].visible = true;
              let tableFirtEmpty = await this.searchDataVariable(this.dataALL[i].tables[x].title);
              this.dataALL[i].currentTablesSelected.push(tableFirtEmpty);
            }
            break;
          }
        }
      }
    },
    async searchDataVariable(nameValue){
      this.currentGroupAux = '';
      for await(const elem of Object.keys(allVariables)) {
        this.currentGroupAux = elem;
        for await(const data of allVariables[elem]) {
          if(data.name == nameValue){
            return data;
          }
        }
      }
      return undefined;
    },    
    dateToday() {
      let date = new Date();
      for (const i in this.dataALL) {
        this.dataALL[i].newDateDay = date.getDate();
        this.dataALL[i].newDateMonth = date.getMonth() + 1;
        this.dataALL[i].newDateYear = date.getFullYear();
        this.dataALL[i].newDateHour = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      }
    },
    deleteItem(data, table, variable){
      this.titleDialog = "ELIMINAR DATO SELECCIONADO";
      this.textDialog = "¿Está seguro que desea eliminar este dato de la tabla?";
      this.showDialogRemove(data, table, variable)
    },
    async resRemove(e) {
      // e.variable ... 
      this.waitLoadScreen = true;
      this.textLoader = "Eliminando..."

      let deleteCollection = await axios.delete(this.urlDataRecords + '/' + e.table.name + '/' + e.data.id,
        {
          headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userLocal.token}`
          }
        }
      )
      .then(async authoritation => {
        this.waitLoadScreen = false;
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        return undefined;
      });
      if(!deleteCollection){
        this.waitLoadScreen = false;
        this.titleDialog = "ERROR DE DATOS";
        this.textDialog = "Ha surgido un error al eliminar un dato en la colección: " + e.table.name;
        this.showDialogError();
      }
    },
    async editChangesTable(item, itemTable){
      for (const i of Object.keys(item)) {
        for (const edit of itemTable.editValues) {
          if(i == 'date'){
            let time = item[i].split("T")[1];
            item[i] = itemTable.editValueYear + "-" + this.addZero(itemTable.editValueMonth) + "-" + this.addZero(itemTable.editValueDay) + "T" + time;
          }
          else if(i == 'value.x' && edit.type == 'obj'){
            item[i] = edit.value.x;
          }
          else if(i == 'value.y' && edit.type == 'obj'){
            item[i] = edit.value.y;
          }
          else if(i == 'value.z' && edit.type == 'obj'){
            item[i] = edit.value.z;
          }
          else if(i == edit.name){
            item[i] = edit.value;
          }
        }
      }
      let tagsModify = [];
      for (const elem of itemTable.editValues) {
        if(elem.name != "value" && elem.name != "Unidad" && elem.name != "Usuario"){
          tagsModify.push(
            {
              name: elem.name,
              value: elem.value
            }
          );
        }
      }
      let bodyPOST =
      {
        id: item.id,
        field: itemTable.name,
        date: item.date,
        value: item.value,
        tags: tagsModify,
      };
      this.waitLoadScreen = true;
      this.textLoader = "Modificando..."

      let modCollection = await axios.put(this.urlDataRecords + '/' + itemTable.name + '/' + item.id,
        bodyPOST,
        {
          headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userLocal.token}`
          }
        }
      )
      .then(async authoritation => {
        this.waitLoadScreen = false;
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        return undefined;
      });
      if(!modCollection){
        this.waitLoadScreen = false;
        this.titleDialog = "ERROR DE DATOS";
        this.textDialog = "Ha surgido un error al modificar un dato en la colección: " + itemTable.name;
        this.showDialogError();
      }
    },
    addZero(num){
      let part = num.split("");
      if(part.length == 1){
        return "0" + num;
      }
      else{
        return num;
      }
    },
    loadInfoMod(item, itemTable){
      let part = item.date.split("T")[0].split("-");
      itemTable.editValueDay = part[2];
      itemTable.editValueMonth = part[1];
      itemTable.editValueYear = part[0];

      for (const value of Object.keys(item)){
        for (const edit of itemTable.editValues){
          if(value == edit.name.replace(" ", "")){
            edit.value = item[value];
          }
          else if(value == "value.x"){
            if(edit.value != undefined && edit.type == 'obj'){
              edit.value.x = item[value];
            }
          }
          else if(value == "value.y"){
            if(edit.value != undefined && edit.type == 'obj'){
              edit.value.y = item[value];
            }
          }
          else if(value == "value.z"){
            if(edit.value != undefined && edit.type == 'obj'){
              edit.value.z = item[value];
            }
          }
        }
      }
    },
    formatTextValue(name){
      return "valor " + name;
    },
    arrayNewData(itemTable){
      let arrayAUX = [];
      for (const i in itemTable) {
        if (itemTable[i].name != 'value' && itemTable[i].name != 'valueObj' && itemTable[i].name != 'unit' && itemTable[i].name != 'Variable') {
          arrayAUX.push(itemTable[i]);
        }
      }
      return arrayAUX;
    },
    emptyDataNew(arrayObj){
      let objAux = {}
      for (const i of Object.keys(arrayObj)) {
        objAux[i] = '';
      }
      return objAux;
    },
    async addNewRow(itemTable, itemList) { 
      if(itemTable.newValueDay != '' && itemTable.newValueDay != undefined &&
          itemTable.newValueMonth != '' && itemTable.newValueMonth != undefined &&
          itemTable.newValueYear != '' && itemTable.newValueYear != undefined &&
          this.confirmDate(itemTable.newValueDay, itemTable.newValueMonth, itemTable.newValueYear) == true
        ){
          let flag = true;
          for (const elem of itemTable.newValues) {
            if (elem.type == 'obj') {
              
              for (const i of Object.keys(elem.value)) {
                if(elem.value[i] == '' || elem.value[i] == undefined){
                  flag = false;
                }
              }
            }
            else{
              if(elem.value == '' || elem.value == undefined){
                flag = false;
              }
            }
          }
          if(flag == true){
            let flatObj = {
              id: itemTable.data.length,
              menuEdit: false,
              nameTable: itemTable.name,
              posSection: itemList.id,
              user: this.currentNameUser 
            };
            let date = new Date();
            let newHour = this.addZero(String(date.getHours())) + ":" + this.addZero(String(date.getMinutes())) + ":" + this.addZero(String(date.getSeconds()));
            let newDateComplet = itemTable.newValueYear + "-"+this.addZero(itemTable.newValueMonth)+"-"+this.addZero(itemTable.newValueDay)+"T" + newHour;
            flatObj['date'] = newDateComplet;
            let objs_names = itemTable.newValues.filter(x => x.type === "obj").map(y => y.name.split(".")[0]).filter((value, index, self) => self.indexOf(value) === index);
            let value = itemTable.newValues.find(x => x.name === "value");
            if(!value){
              let value_records = itemTable.newValues.filter(x => x.name.startsWith("value"))
              value = {};
              for(let vr of value_records){
                let field_parts = vr.name.split(".");
                if(field_parts.length > 1){
                  let field = field_parts[1]
                  value[field] = vr.value
                  flatObj[field] = vr.value
                }
                else {
                  for(let val of Object.keys(vr.value)){
                    value["value." +  val] = vr.value[val]
                    flatObj["value." +  val] = vr.value[val]
                  }
                }
              }
            }
            else {
              flatObj["value"] = value.value;
              value = value.value;
            }
            let result = {
              tags: [],
              value: value,
              field: itemTable.name
            };
            let inserted = []
            for(let name of objs_names){
              let values = itemTable.newValues.filter(x => x.name.startsWith(name));
              inserted.push(...values)
              let obj = {};
              for(let value of values){
                let part = value.name.split(".");
                if(part.length > 1){
                  let path = part[1];
                  obj[path] = value.value
                  flatObj[(name + "." + path).replace(" ","")] = value.value
                }
              }
              result.tags.push({
                name: name,
                value: obj
              })
            }
            let inserted_names = inserted.map(x => x.name)
            let other_tags = itemTable.newValues.filter(x => !inserted_names.includes(x.name) && x.name !== "unit" && !x.name.startsWith("value"));
            for(let ot of other_tags){
              flatObj[ot.name.replace(" ","")] = ot.value
              result.tags.push({
                name: ot.name,
                value: ot.value
              })
            }
            flatObj.unit = itemTable.newValues.find(x => x.name === "unit").value;
            this.addNewDataSection(itemTable, result, flatObj);
          }
          else{
            this.titleDialog = "ERROR - DATOS INCOMPLETOS";
            this.textDialog = "Se deben rellenar todos los campos solicitados.";
            this.showDialogError();
          }
        }
      else{
        this.titleDialog = "FECHA ERRÓNEA";
        this.textDialog = "Introducir una fecha válida en el formato de: día-mes-año";
        this.showDialogError();
      }
      
      // reset date
      this.dateToday();
      
    },
    confirmDate(day, month, year){
      let result = new Date(year+"/"+this.addZero(month)+"/"+this.addZero(day));
      if(result != 'Invalid Date'){
        return true;
      }
      return false;
    },
    addNewDataSection(itemTable, body, flatObj){
      let objNew = flatObj;
      let date = new Date();
      let newHour = this.addZero(String(date.getHours())) + ":" + this.addZero(String(date.getMinutes())) + ":" + this.addZero(String(date.getSeconds()));
      let newDateComplet = itemTable.newValueYear + "-"+this.addZero(itemTable.newValueMonth)+"-"+this.addZero(itemTable.newValueDay)+"T" + newHour;
      body['date'] = newDateComplet;

      itemTable.data.push(objNew);
      
      this.saveDataDB(body); // save info to DB

      this.resetAddNew(itemTable); // Reset values
    },
    async saveDataDB(bodyPOST){
      this.waitLoadScreen = true;
      this.textLoader = "Guardando..."
      this.authoritation = await axios.post(this.urlDataRecords,
        [bodyPOST],
        {
          headers:{ 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userLocal.token}`
          },
        }
      )
      .then(authoritation => {
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        return undefined;
      }); 
      if(this.authoritation){
        this.waitLoadScreen = false;
      }
      else{
        this.waitLoadScreen = false;
        this.titleError = 'ERROR DE DATOS';
        this.textError = 'Se ha producido un error al guardar la información en la base de datos.';
        this.showDialogUserDenied();
      }   
    },
    resetAddNew(itemTable){
      let date = new Date();
      let todayDay = String(date.getDate());
      let todayMonth = String(date.getMonth() + 1);
      let todayYear = String(date.getFullYear());

      itemTable.newValueDay = todayDay;
      itemTable.newValueMonth = todayMonth;
      itemTable.newValueYear = todayYear;

      for (const elem of itemTable.newValues) {
        elem.value = undefined;
      }
    },
    numZero(num) {
      if (num < 10) {
        return "0" + num;
      } 
      else {
        return num;
      }
    },  
    loadDataExcel(item){
      if(item.fileExcel != null ){
        let nameFile = String(item.fileExcel.name);
        let extension = nameFile.split(".")[1];
        if(extension == 'xlsx' || extension == 'xlsb'){
          this.waitLoadScreen = true;
          this.processFileExcel(item);
          this.waitLoadScreen = false;
        }else{
          this.titleDialog = "ARCHIVO EXCEL NO VÁLIDO";
          this.textDialog = "El formato del archivo excel sólo puede ser en .xlsx ó .xlsb";
          this.showDialogError(); // show modal dialog Error Format
        }
      }
      else{
        this.titleDialog = "ARCHIVO EXCEL NO ENCONTRADO";
        this.textDialog = "Debe Seleccionar primero un fichero excel en un formato válido";
        this.showDialogError(); // show modal dialog Error Empty
      }
    },
    async processFileExcel(item){
      this.waitLoadScreen = true;
      this.textLoader = "Guardando Excel..."
      let formData = new FormData();
      formData.append('file', item.fileExcel);
      formData.append('field', item.name);
      await axios
      .post(
        this.urlDataRecords,
        formData, {
          headers:{
            'Content-Type': 'multipart/form-data',
            "Authorization": `Bearer ${this.userLocal.token}`
          }
        }
      ).then(function () {
        this.titleDialog = "ARCHIVO EXCEL CARGADO CON ÉXITO";
        this.textDialog = "Se han cargado todos los datos del excel en la tabla correspondiente";
        this.showDialogSuccessExcel(); // show modal dialog success various animals
      }.bind(this)).catch(function () {
        this.titleDialog = "ERROR AL CARGAR EL FICHERO EXCEL";
        this.textDialog = "Lo sentimos, por algún motivo el fichero excel no se ha cargado correctamente";
        this.showDialogError(); // show modal dialog error various animals
      }.bind(this));
    },
    activateBtnExcel(itemTable){
      if(itemTable.fileExcel != null && itemTable.fileExcel != ''){
        itemTable.disableExcel = true;
      }
      else{
        itemTable.disableExcel = false;
      }
    },
    downloadTemplateExcel(itemTable){
      this.dataExcelTemplate = {};
      let arrayExcel = [];
      for (const elem of itemTable.headers) {
        if(elem.text != 'Acciones' && elem.text != 'Unidad' && elem.text != 'Usuario'){
          this.dataExcelTemplate[elem.text] = itemTable.data[0][elem.value];
        }
      }
      arrayExcel.push(this.dataExcelTemplate);
      try {
        json2excel({
          data: arrayExcel,
          name: 'Plantilla_excel_' + itemTable.name,
          formateDate: 'dd/mm/yyyy'
        })        
      } catch (error) {
        console.error(error);
      }
    },
    downloadDataExcel(itemTable){
      this.dataExcelTemplate = {};
      let localExcelObj = {};
      let arrayExcel = [];
      // Recorremos cada fila de la tabla...
      for(const obj of itemTable.data){
        //... y recorremos cada columna de la tabla ...
        for (const elem of itemTable.headers) {
          if(elem.text != 'Acciones' && elem.text != 'Unidad' && elem.text != 'Usuario'){
            //... y se añade el valor que tiene.
            localExcelObj[elem.text] = obj[elem.value];
          }
        }
        // Añadir la fila de datos a la tabla y resetear el objeto de la fila para la siguiente
        arrayExcel.push(localExcelObj)
        localExcelObj = {}
      }
      try {
        // Crear el fichero excel con la tabla (las cabeceras las calcula y las inserta automáticamente por la librería)
        json2excel({
          data: arrayExcel,
          name: 'Excel_datos_' + itemTable.name,
          formateDate: 'dd/mm/yyyy'
        })        
      } catch (error) {
        console.error(error);
      }
    },
    goToGisMap(data, table, variable){
      this.$router.push({
        path: "/home/gismap",
        name: 'gismap',
        params: {
          data: data,
          table: table,
          variable: variable
        },
      });
    },
    showIconMap(data){
      if(data.value){
        return false;
      }
      return true;
    },

    //------------------------------  DIALOG ------------------------------
    async showDialogRemove(data, table, variable) {
      try {
        await this.$refs.dialogRemove.open(
          this.titleDialog,
          this.textDialog,
          data, 
          table,
          variable
        );
      } 
      catch (error) {
        console.error(error);
      }
    },
    async showDialogError() {
      try {
        await this.$refs.dialogError.open(
          this.titleDialog,
          this.textDialog
        );
      } 
      catch (error) {
        console.error(error);
      }
    },
    async showDialogSuccessExcel() {
      try {
        await this.$refs.dialogSuccess.open(
          this.titleDialog,
          this.textDialog
        );
      } 
      catch (error) {
        console.error(error);
      }
    },
    async showDialogLogout() {
      try {
        await this.$refs.dialogLogout.open(
          "CERRAR SESIÓN",
          "¿Está seguro de que desea abandonar su sesión de usuario actual?"
        )
      } catch (error) {
          console.error(error);
      }
    },
  },
};
</script>

<style scoped>
  .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
      border: none !important;
  }
  .v-expansion-panel::before {
      box-shadow: none;
  }
</style>